import {defineStore} from 'pinia'
import {ref} from 'vue'
import {useToast} from "vue-toastification";
import {$larafetch} from "~/utils/$larafetch";

export const useLinkedDashboardsStore = defineStore('linkedDashboards', () => {
    const addedDashboards = ref([])
    const removedDashboards = ref([])
    const dashboards = ref([])
    const dashboardsLoading = ref(false)

    function fetchDashboards() {
        dashboardsLoading.value = true;

        $larafetch(useNuxtApp().$apiRoute('spa.dashboards.index'), {
            method: "GET",
            params: {
                output: 'select-sorted',
                orderBy: 'name',
                orderDirection: 'asc',
            }
        }).then(response => {
            dashboards.value = Object.values(response)
        }).catch(error => {
            useToast().error(error.data.message)
            dashboards.value = []
        }).finally(() => {
            dashboardsLoading.value = false;
        })
    }

    const dashboardsWithGroupId = computed(() => {
        return dashboards.value
            .filter(dashboard => dashboard.organisation_id !== null)
            .map(dashboard => {
            let dashboardGroupId = null;

            if (dashboard.linked_child_dashboards?.length > 0) {
                dashboardGroupId = dashboard.linked_child_dashboards[0].pivot.group_id;
            } else if (dashboard.linked_parent_dashboards?.length > 0) {
                dashboardGroupId = dashboard.linked_parent_dashboards[0].pivot.group_id;
            }

            return {...dashboard, group_id: dashboardGroupId};
        });
    })

    const dashboardGroups = computed(() => {
        const groupIds = dashboardsWithGroupId.value.map(dashboard => dashboard.group_id);
        const filteredGroupIds = groupIds.filter(groupId => groupId !== null);
        const uniqueGroupIds = [...new Set(filteredGroupIds)];
        return Array.from(uniqueGroupIds)
    })

    return {
        dashboards,
        dashboardsWithGroupId,
        addedDashboards,
        removedDashboards,
        dashboardGroups,
        dashboardsLoading,
        fetchDashboards
    }
})